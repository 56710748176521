import { useFormik } from "formik";
import React, { useEffect, useState, useMemo } from "react";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import useApi from "common/ApiMiddlewere";
import toast from "react-hot-toast";
import * as Yup from "yup";
import DeleteModel from "common/deleteModel/deleteModel";
import BookingItemTableList from "./BookingItemTableList";
import { dateFormat } from "pages/Common";

let isRefundDeposit = false
let incomeWriteRemarks = ''
const CommonModel = ({
    modal,
    toggle,
    BookingandStatus,
    flag,
}) => {
    const { postApi, putApi, getApi } = useApi()
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [billAmountData, setBillAmountData] = useState()
    const [discountAmount, setDiscountAmount] = useState(0)
    const [flagForAllDelivery, setFlagForAllDelivery] = useState(false)
    const [isRefund, setIsRefund] = useState(false)
    const [isDepositIncome, setIsDepositIncome] = useState(false)
    const [validationFlag, setValidationFlag] = useState(false)
    const [paymentMethod, setpaymentMethod] = useState("1")
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [depositModel, setDepositModel] = useState(false)
    const [incomeRemarks, setIncomeRemarks] = useState('')
    const [bookingItemStatusChangemodel, setBookingItemStatusChangemodel] = useState(false)
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [bookingItemIds, setBookingItemIds] = useState([BookingandStatus?.bookingItemId])
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [incomeError, setIncomeError] = useState(false);

    useEffect(() => {
        if ((parseInt(BookingandStatus.statusId) == 1 || parseInt(BookingandStatus.statusId) == 2)) {
            isRefundDeposit = false
        } else if ((parseInt(BookingandStatus.statusId) == 3 || parseInt(BookingandStatus.statusId) == 4)) {
            isRefundDeposit = true
        }
    }, [])

    const handleIncomeremark = (value) => {
        setIncomeRemarks(value)
        incomeWriteRemarks = value
        setIncomeError(false)
    }

    // subscription Validations
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: billAmountData ? billAmountData.NetAmount : 0,
            discount: 0,
            advance: billAmountData ? billAmountData.Advance : 0,
            deposit: isRefundDeposit ? 0 : billAmountData?.Deposit || 0,
            enterAmount: 0,
            advancedMinusRefund: 0,
            month: 6,
            returnRemark: '',
        },
        validationSchema: Yup.object({
        }),
        onSubmit: values => {

            // TODO: Optimize this condetion.
            if ((values.deposit > billAmountData?.Deposit) && isRefundDeposit) {
                console.log("yes")
                return false
            }
            if (values.deposit < 0 || values.advance < 0) {
                console.log("yes")
                return false
            }
            if (flag == "cancel" && (values.advance < values.enterAmount)) {
                console.log("yes")
                return false
            }
            if (flag != "cancel" && flag != "settle" && ((values.amount - values.advance - values.enterAmount - values.discount) < 0)) {
                console.log("yes")
                return false
            }
            if (flag == "settle" && !isRefund && ((values.amount - values.advance - values.enterAmount - values.discount) < 0) && (values.enterAmount < 0 || ((values.amount - values.advance - values.enterAmount - values.discount) < 0)) && (validationFlag === false)) {
                console.log("yes")
                return false
            }
            if (flag != "cancel" && flag != "settle" && (values.enterAmount < 0 || ((values.amount - values.advance - values.enterAmount - values.discount) < 0) && (validationFlag == false))) {
                console.log("yes")
                return false
            }
            if (isDiscountInvalid()) {
                console.log("yes")
                return false
            }
            if (isRefund && (values.advance - values.enterAmount < 0 ? true : false) && (validationFlag == false)) {
                console.log("yes")
                return false
            }
            if (flag != "cancel" && flag != "settle" && ((validationFlag == false) &&
                (values.enterAmount < 0 || ((values.amount - values.advance - values.enterAmount - values.discount) < 0)))) {
                console.log("yes")
                return false
            }
            if (isRefund && (values.advance - values.enterAmount < 0 ? true : false) && (validationFlag == false)) {
                console.log("yes")
                return false
            }

            setformSumitLoading(true)
            if (flag == "cancel") {
                DeleteModel.confirmDeleteInquiry({
                    title: "Are you sure to cancel this bill? ",
                    text: "Once the bill is cancelled you cannot reverse it.",
                    isMobile,
                }).then(response => {
                    if (response == true) {
                        let obj = {
                            bookingId: BookingandStatus.bookingId,
                            bookingItemIds: '',
                            BookingStatus: parseInt(BookingandStatus.statusId),
                            returnRemarks: values.returnRemark
                        }
                        putApi("Booking/update-advance", {
                            bookingId: BookingandStatus.bookingId,
                            amount: parseInt(values.enterAmount) || 0,
                            isRefund: true,
                            deposit: values.deposit || 0,
                            isRefundDeposit: isRefundDeposit,
                            discount: (parseInt(values.discount ? values.discount : 0) || 0),
                            bookingStatus: parseInt(BookingandStatus.statusId),
                            paymentMode: parseInt(paymentMethod),
                            writeIncome: isDepositIncome,
                            writeIncomeRemarks: incomeRemarks,
                        }).then(({ data, status }) => {
                            setformSumitLoading(false)
                            if (status === 200) {
                                setIsDepositIncome(false)
                                putApi("Booking/change-Status", obj).then(({ data, status }) => {
                                    if (status === 200) {
                                        toast.success("Status change sucessfully", {
                                            style: {
                                                fontSize: "15px",
                                            },
                                        })
                                        validation.handleReset()
                                        toggle()
                                    } else {
                                        toast.error(data, {
                                            style: {
                                                fontSize: "15px",
                                            },
                                        })
                                    }
                                })

                            } else {
                                toast.error(data, {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                                setformSumitLoading(false)
                            }
                        })
                    } else {
                        toggle()
                    }
                })
            } else if (flag == "settle") {
                putApi("Booking/update-advance", {
                    bookingId: BookingandStatus.bookingId,
                    amount: parseFloat(values.enterAmount) || 0,
                    isRefund: isRefund ? true : false,
                    isRefundDeposit: isRefundDeposit,
                    deposit: values.deposit || 0,
                    status: parseInt(BookingandStatus.statusId),
                    discount: (parseFloat(values.discount ? values.discount : 0) || 0),
                    paymentMode: parseInt(paymentMethod),
                    writeIncome: isDepositIncome,
                    writeIncomeRemarks: incomeRemarks,
                }).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 200) {
                        setIsDepositIncome(false)
                        toast.success("Amount change sucessfully", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        validation.handleReset()
                        toggle()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        setformSumitLoading(false)
                    }
                })
            } else {
                let obj = {
                    bookingId: (flagForAllDelivery || flag == "delivery" || flag == "cancelItem") ? 0 : BookingandStatus.bookingId,
                    bookingItemIds: bookingItemIds.join(","),
                    BookingStatus: parseInt(BookingandStatus.statusId),
                    returnRemarks: values.returnRemark
                }
                putApi("Booking/update-advance", {
                    bookingId: BookingandStatus.bookingId,
                    amount: parseFloat(values.enterAmount) || 0,
                    isRefund: (flag == "cancel" || flag == "cancelItem") ? true : false,
                    deposit: values.deposit || 0,
                    isRefundDeposit: isRefundDeposit,
                    discount: parseFloat(values.discount ? values.discount : 0) || 0,
                    bookingStatus: parseInt(BookingandStatus.statusId),
                    paymentMode: parseInt(paymentMethod),
                    writeIncome: isDepositIncome,
                    writeIncomeRemarks: incomeRemarks,
                }).then(({ data, status }) => {
                    if (status === 200) {
                        setIsDepositIncome(false)
                        putApi("Booking/change-Status", obj).then(({ data, status }) => {
                            if (status === 200) {
                                toast.success("Status change sucessfully", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                                validation.handleReset()
                                setformSumitLoading(false)
                                toggle()
                            } else {
                                setformSumitLoading(false)
                                toast.error(data, {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            }
                        })

                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        setformSumitLoading(false)
                    }
                })
            }
        },
    })

    const depositToggle = () => {
        setDepositModel(!depositModel)
    }

    useEffect(() => {
        validation.handleReset()
        if (BookingandStatus.bookingId) {
            validation.handleReset()
            postApi("Booking/bill-amounts", {
                bookingId: BookingandStatus.bookingId
            }).then(({ data, status }) => {
                if (status === 200) {
                    setBillAmountData(data)
                    setDiscountAmount(data.Discount)
                    setpaymentMethod(data?.PaymentMode == 2 ? "2" : "1");
                }
            });
        } else {
            toast.error("BookingId not found", {
                style: {
                    fontSize: "15px",
                },
            })
        }

    }, [flag, BookingandStatus.bookingId])

    const handleDiscountChange = value => {
        validation.setFieldValue("discount", value)
        setValidationFlag(true)
    }

    const handleEnterAmountChange = value => {
        validation.setFieldValue("enterAmount", value)
        setValidationFlag(false)
    }

    const { discount, amount, advance, enterAmount, deposit } = validation.values;

    const formattedDiscount = Math.round(discountAmount + parseInt((discount ? discount : 0))).toLocaleString('en-IN', { currency: 'INR' }) + ".00"

    const PendingDeposit = billAmountData?.Deposit - parseInt(deposit ? deposit : 0)

    const isDiscountInvalid = () => {
        const remainingAmount = amount - advance - enterAmount - discount;
        return validationFlag && (discount < 0 || remainingAmount < 0);
    };

    const discountErrorMessage = () => {
        const remainingAmount = amount - advance - enterAmount - discount;
        return remainingAmount < 0 ? "The discount does not exceed the bill amount" : "Discount should not be less than zero";
    };

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const incomeTransactionSubmit = () => {
        console.log("incomeWriteRemarks", incomeWriteRemarks)
        if (incomeWriteRemarks == '') {
            setIncomeError(true)
        } else {
            setIncomeError(false)
            setIsDepositIncome(true)
            depositToggle()
        }
    }

    const incomeTransactionCancel = () => {
        setIsDepositIncome(false)
        depositToggle()
    }

    const productsReturnAndDeliver = (value) => {
        setFlagForAllDelivery(value)
        if (value) {
            bookingItemStatusChangeToggle()
            setIsLoading(true)
            getApi(`Booking/booking-item-list?id=${String(BookingandStatus.bookingId)}`).then(
                ({ data, status }) => {
                    if (status === 200) {
                        setIsLoading(false)
                        setTableData(data)
                    } else {
                        setIsLoading(false)
                    }
                }
            )
        } else {
            setBookingItemIds([BookingandStatus.bookingItemId])
        }
    }

    const bookingItemStatusChangeToggle = () => {
        setBookingItemStatusChangemodel(!bookingItemStatusChangemodel)
    }

    const handleCheckboxChange = (bookingItemId, value) => {
        setBookingItemIds((prevBookingItemIds) => {
            if (value) {
                setIsError(false)
                return [...prevBookingItemIds, bookingItemId];
            } else {
                return prevBookingItemIds.filter(id => id !== bookingItemId);
            }
        });
    }

    const handleCancle = () => {
        setBookingItemIds([BookingandStatus.bookingItemId])
        productsReturnAndDeliver(false)
        setFlagForAllDelivery(false)
        setIsError(false)
        setErrorMessage("")
        bookingItemStatusChangeToggle()
    }

    const handleSave = () => {
        if (bookingItemIds.length == 0) {
            setIsError(true)
            setErrorMessage("Please select at least one product for this booking.")
        } else {
            setIsError(false)
            setErrorMessage("")
            bookingItemStatusChangeToggle()
        }
    }

    const handleMasterCheckboxChange = (isChecked) => {
        setIsError(isChecked)
        setErrorMessage(isChecked && '')
        const newBookingItemIds = isChecked
            ?
            tableData
                .filter(
                    (item) =>
                        item.Status !== BookingandStatus.statusId &&
                        ((!BookingandStatus?.returned && item.Status == 1) ||
                            (BookingandStatus?.returned && item.Status !== 1)) &&
                        item.Status !== 3 &&
                        item.Status !== 4
                )
                .map((item) => item.Id)
            : [];
        setBookingItemIds(newBookingItemIds);
    };

    const columns = useMemo(
        () => [
            {
                Header: (
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={
                            tableData.filter(
                                (item) =>
                                    item.Status !== BookingandStatus.statusId &&
                                    ((!BookingandStatus?.returned && item.Status == 1) ||
                                        (BookingandStatus?.returned && item.Status !== 1)) &&
                                    item.Status !== 3 &&
                                    item.Status !== 4
                            ).length === bookingItemIds.length &&
                            bookingItemIds.length > 0
                        }
                        onChange={(e) => handleMasterCheckboxChange(e.target.checked)}
                    />
                ),
                accessor: "Id",
                width: "4%",
                Cell: (row, index) => {
                    const { Id, Status } = row.row.original;
                    return (
                        <>
                            <div key={index}>
                                <input
                                    disabled={(Status == parseInt(BookingandStatus.statusId) || Status == 3 || Status == 4) || (BookingandStatus?.returned && Status == 1)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={bookingItemIds.includes(Id)}
                                    onChange={e => handleCheckboxChange(Id, e.target.checked)}
                                />
                            </div>
                        </>
                    );
                },
            },
            {
                Header: "Code",
                accessor: "ProductCode",
                width: "5%",
            },
            {
                Header: "Delivery",
                accessor: "DeliveryDate,DeliveryTimeSlot",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.DeliveryDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                        {row.original.DeliveryTimeSlot ? (
                            <span> {` (${row.original.DeliveryTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Return",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReturnDate == null
                            ? "N/A"
                            : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
                        {row.original.ReturnTimeSlot ? (
                            <span> {` (${row.original.ReturnTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Status",
                accessor: "Status",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.Status == 1 ? "Booked" : row.original.Status == 2 ? "Delivered" : row.original.Status == 3 ? "Return" : "Cancel"}
                    </p>
                ),
            },
        ],
        [tableData, bookingItemIds, BookingandStatus, isError, flagForAllDelivery, bookingItemStatusChangemodel]
    )

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="lg"
                style={{ width: "750px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        Bill No: {billAmountData?.BillNo}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Bill Amount
                                        </Label>
                                        <Input
                                            name="amount"
                                            disabled
                                            placeholder="0"
                                            type="text"
                                            className="form-control-sm"
                                            value={validation.values.amount}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Total Discount:
                                            <span style={{ color: "green", fontSize: "13px" }}>
                                                <span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>
                                                    ₹
                                                </span>
                                                {formattedDiscount}
                                            </span>
                                        </Label>
                                        <Input
                                            name="discount"
                                            placeholder="0"
                                            type="number"
                                            className="form-control-sm"
                                            onChange={(e) => {
                                                handleDiscountChange(e.target.value);
                                            }}
                                            onKeyPress={handleKeyPress}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.discount}
                                            onFocus={(e) => { e.target.select(); }}
                                            invalid={isDiscountInvalid()}
                                        />
                                        {isDiscountInvalid() && (
                                            <FormFeedback type="invalid">
                                                {discountErrorMessage()}
                                            </FormFeedback>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    {isRefundDeposit ?
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03">
                                                Security Amt.:
                                                <span style={{ color: "green", fontSize: "13px" }}>
                                                    <span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>
                                                        ₹
                                                    </span>
                                                    {new Intl.NumberFormat('en-IN', {
                                                        currency: 'INR',
                                                        minimumFractionDigits: 2,
                                                    }).format(Math.round(parseFloat(PendingDeposit || 0)))}
                                                </span>
                                            </Label>
                                            <InputGroup style={{ paddingRight: "0px" }}>
                                                <Input
                                                    name="deposit"
                                                    placeholder="0"
                                                    type="text"
                                                    className="form-control-sm"
                                                    value={validation.values.deposit}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    onFocus={(e) => { e.target.select() }}
                                                    invalid={(validation.values.deposit > billAmountData?.Deposit)}
                                                />
                                                <div className="input-group-append">
                                                    {(parseFloat(PendingDeposit) > 0) ?
                                                        (
                                                            <button
                                                                title="Generate Income"
                                                                type="button"
                                                                style={{
                                                                    width: "28px",
                                                                    color: "#3834349c",
                                                                    cursor: "pointer",
                                                                    minHeight: "27px",
                                                                    border: "1px solid  #ced4da",
                                                                    backgroundColor: "#f8f9fa",
                                                                }}
                                                                onClick={() => depositToggle()}
                                                            >
                                                                <span>
                                                                    <i className="fas fa-rupee-sign" style={{ marginTop: "5px" }} />
                                                                </span>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                title="Generate Income"
                                                                type="button"
                                                                style={{
                                                                    width: "28px",
                                                                    color: "#3834349c",
                                                                    cursor: "no-drop",
                                                                    minHeight: "27px",
                                                                    border: "1px solid  #ced4da",
                                                                    backgroundColor: "#f8f9fa",
                                                                }}
                                                            >
                                                                <span>
                                                                    <i className="fas fa-rupee-sign" style={{ marginTop: "5px" }} />
                                                                </span>
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                                {(validation.values.deposit > billAmountData?.Deposit) ? (
                                                    <FormFeedback type="invalid">
                                                        Security amount should not be grater than {billAmountData?.Deposit}

                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </FormGroup>
                                        :
                                        <>
                                            <FormGroup >
                                                <Label htmlFor="validationCustom03">
                                                    Security Amt.
                                                </Label>
                                                <InputGroup style={{ paddingRight: "0px" }}>
                                                    <Input
                                                        name="deposit"
                                                        placeholder="0"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onKeyPress={handleKeyPress}
                                                        value={validation.values.deposit}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={(validation.values.deposit < 0)}
                                                    />
                                                    {(validation.values.deposit < 0) ? (
                                                        <FormFeedback type="invalid">
                                                            Security amount should not be less than zero
                                                        </FormFeedback>
                                                    ) : null}
                                                </InputGroup>
                                            </FormGroup>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">Advance</Label>
                                        <Input
                                            name="advance"
                                            placeholder="0"
                                            type="text"
                                            disabled
                                            className="form-control-sm"
                                            value={validation.values.advance}
                                            invalid={validation.values.advance < 0}
                                        />
                                    </FormGroup>
                                </Col>
                                {flag == "booking"
                                    || flag == "delivery" ?
                                    (
                                        <Col md={4}>
                                            <FormGroup className="mb-1">
                                                <Label htmlFor="validationCustom03">
                                                    Receive
                                                </Label>
                                                <Input
                                                    name="enterAmount"
                                                    placeholder="0"
                                                    type="number"
                                                    className="form-control-sm"
                                                    // onChange={validation.handleChange}
                                                    onKeyPress={e => {
                                                        if (e.key === '-' || e.key === 'e') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={e => {
                                                        handleEnterAmountChange(e.target.value)
                                                    }}

                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.enterAmount}
                                                    onFocus={(e) => { e.target.select() }}
                                                    invalid={(validation.values.enterAmount < 0
                                                        || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) && (validationFlag == false))}

                                                />
                                                {(validation.values.enterAmount < 0
                                                    || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0)) && (validationFlag == false) ? (
                                                    <FormFeedback type="invalid">
                                                        {((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) ?
                                                            "Payble Amount Not grater than Bill Amount"
                                                            : "Receive should not be less than zero"}
                                                    </FormFeedback>
                                                )
                                                    : null}
                                            </FormGroup>
                                        </Col>
                                    )
                                    : (flag == "settle") ? (
                                        <Col md={4}>
                                            <div className=" d-flex gap-2">
                                                <input
                                                    type="radio"
                                                    value={false}
                                                    id="customRadioInline1"
                                                    className="form-check-input"
                                                    checked={isRefund == false}
                                                    onClick={() => { setIsRefund(false) }}
                                                />
                                                <Label className="form-check-label font-size-13" style={{ marginRight: "7px" }} htmlFor="customRadioInline1">
                                                    Receive
                                                </Label>

                                                <input
                                                    type="radio"
                                                    value={true}
                                                    id="customRadioInline2"
                                                    className="form-check-input"
                                                    checked={isRefund == true}
                                                    onClick={() => { setIsRefund(true) }}
                                                />
                                                <Label className="form-check-label font-size-13" htmlFor="customRadioInline2">
                                                    Refund
                                                </Label>
                                            </div>
                                            {isRefund ?
                                                <div>
                                                    <Input
                                                        name="enterAmount"
                                                        placeholder="0"
                                                        type="number"
                                                        className="form-control-sm"
                                                        // onChange={validation.handleChange}
                                                        onKeyPress={e => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            handleEnterAmountChange(e.target.value)
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.enterAmount}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={
                                                            (validation.values.advance - validation.values.enterAmount < 0 ? true : false)
                                                            && (validationFlag == false)
                                                        }
                                                    />
                                                    {(validation.values.advance
                                                        - validation.values.enterAmount < 0)
                                                        && (validationFlag === false) ? <>
                                                        <FormFeedback type="invalid">
                                                            Advance must be greater than or equal refund
                                                        </FormFeedback>
                                                    </> :
                                                        null}
                                                </div>
                                                :
                                                <div>
                                                    <Input
                                                        name="enterAmount"
                                                        placeholder="0"
                                                        type="number"
                                                        className="form-control-sm"
                                                        // onChange={validation.handleChange}
                                                        onKeyPress={e => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            handleEnterAmountChange(e.target.value)
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.enterAmount}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={(validationFlag == false) &&
                                                            (validation.values.enterAmount < 0
                                                                || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0))}

                                                    />
                                                    {(validation.values.enterAmount < 0
                                                        || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0))
                                                        && (validationFlag === false) ? (
                                                        <FormFeedback type="invalid">
                                                            {((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) ?
                                                                "Payble Amount Not greater than Bill Amount"
                                                                : "Receive should not be less than zero"}
                                                        </FormFeedback>
                                                    ) :
                                                        null}
                                                </div>
                                            }
                                        </Col>
                                    ) :
                                        (
                                            <Col md={4}>
                                                <FormGroup className="mb-1">
                                                    <Label htmlFor="validationCustom03">Refund</Label>
                                                    <Input
                                                        name="enterAmount"
                                                        placeholder="0"
                                                        type="number"
                                                        className="form-control-sm"
                                                        // onChange={validation.handleChange}
                                                        onKeyPress={e => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            handleEnterAmountChange(e.target.value)
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.enterAmount}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={(validationFlag == false) &&
                                                            (validation.values.advance - validation.values.enterAmount < 0 ? true : false)
                                                        }
                                                    />
                                                    {validation.values.advance - validation.values.enterAmount < 0 ? (
                                                        <FormFeedback type="invalid">
                                                            Advance must be greater than or equal refund
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        )}
                                {(flag == "booking" || flag == "delivery")
                                    || (flag == "settle" && !isRefund) ?
                                    (
                                        <Col md={4}>
                                            <div style={{ marginBottom: "0px" }}>
                                                <Label htmlFor="validationCustom03"> Total Receive Amount</Label>
                                                <Input
                                                    name="advancedMinusRefund"
                                                    disabled
                                                    type="number"
                                                    placeholder="0"
                                                    className="form-control-sm"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={parseInt(validation.values.advance) + parseInt(validation.values.enterAmount)}

                                                />
                                                <span style={{ color: "green", fontSize: "12px" }}>
                                                    Pending Amount:
                                                    <span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>
                                                        ₹
                                                    </span>
                                                    {(validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount).toLocaleString('en-IN', { currency: 'INR' }) + ".00"}
                                                </span>
                                            </div>
                                        </Col>
                                    ) : (
                                        <Col md={4}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03">
                                                    Total Payble Amount
                                                </Label>
                                                <Input
                                                    name="advancedMinusRefund"
                                                    placeholder="0"
                                                    type="number"
                                                    disabled
                                                    className="form-control-sm"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.advance - validation.values.enterAmount < 0 ? validation.values.advance : validation.values.advance - validation.values.enterAmount}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                            </Row>
                            {BookingandStatus?.statusId == '3' &&
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-2">
                                            <Label htmlFor="validationCustom05">Return Remark</Label>
                                            <Input
                                                name="returnRemark"
                                                rows="1"
                                                placeholder="Type your return remark"
                                                type="textarea"
                                                className="form-control"
                                                value={validation.values.returnRemark || ""}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(!BookingandStatus?.bookingItem && flag == "delivery") &&
                                <div className="d-flex justify-content-start align-items-center gap-2 mt-2">
                                    {
                                        BookingandStatus.returned ?
                                            <>
                                                <Input
                                                    id="checkbox2"
                                                    type="checkbox"
                                                    className="mb-2"
                                                    // defaultChecked={flagForAllDelivery}
                                                    checked={flagForAllDelivery}
                                                    onChange={e => productsReturnAndDeliver(e.target.checked)}
                                                />
                                                <Label htmlFor="checkbox2" className="" title={"If checked, all products under this bill will be marked as returned"}  >
                                                    {"Mark All Products in Bill as Returned"}
                                                </Label>
                                            </>
                                            : <>
                                                <Input
                                                    id="checkbox3"
                                                    type="checkbox"
                                                    className="mb-2"
                                                    // defaultChecked={flagForAllDelivery}
                                                    checked={flagForAllDelivery}
                                                    onChange={e => productsReturnAndDeliver(e.target.checked)}
                                                />
                                                <Label htmlFor="checkbox3" className="" title={"If checked, all products under this bill will be marked as delivered"}>
                                                    {"Mark All Products in Bill as Delivered"}
                                                </Label>
                                            </>
                                    }
                                </div>
                            }

                            <Row className="mt-2">
                                {(billAmountData?.DisplayDeposit > 0
                                    && ((flag == "booking" || flag == "delivery")
                                        && BookingandStatus.statusId == "2")) &&
                                    <Col md={8}>
                                        <Label> Note: </Label>
                                        Collect Security Amt.:
                                        <span style={{ color: "green", fontSize: "13px" }}>
                                            <span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>
                                                ₹
                                            </span>
                                            {billAmountData?.DisplayDeposit + ".00"}
                                        </span>
                                    </Col>
                                }
                                <Col md={(billAmountData?.DisplayDeposit > 0
                                    && ((flag == "booking" || flag == "delivery")
                                        && BookingandStatus.statusId == "2")) ? 2 : 10} className="pe-0">
                                    <div className="mb-2 mt-1">
                                        <div className=" d-flex justify-content-end  gap-2">
                                            <input
                                                type="radio"
                                                value="1"
                                                id="cash"
                                                name="mode"
                                                className="form-check-input"
                                                checked={paymentMethod === "1"}
                                                // onChange={(e) => setpaymentMethod(e.target.checked ? "1" : "2")}
                                                onClick={() => { setpaymentMethod("1") }}
                                            />
                                            <Label className="form-check-label font-size-13" htmlFor="cash">
                                                Cash
                                            </Label>
                                            <input
                                                type="radio"
                                                value="2"
                                                id="bank"
                                                name="mode"
                                                className="form-check-input"
                                                checked={paymentMethod === "2"}
                                                // onChange={(e) => setpaymentMethod(e.target.checked ? "2" : "1")}
                                                onClick={() => { setpaymentMethod("2") }}
                                            />
                                            <Label className="form-check-label font-size-13" htmlFor="bank">
                                                Bank
                                            </Label>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className="d-flex justify-content-end align-items-center gap-2">
                                        {formSumitLoading ? (
                                            <button type="button" className="btn btn-primary btn-sm">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                Loading
                                            </button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                className="btn btn-primary btn-sm "
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>

            <Modal
                isOpen={depositModel}
                toggle={depositToggle}
                centered={true}
                size="small"
                style={{ width: "410px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={depositToggle} tag="h5">
                        {"Security Amt. to Income voucher "}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row className="mb-2" >
                                <Col md={12} style={{ fontWeight: "500" }}>
                                    Your income will generate by an amount of ₹
                                    {new Intl.NumberFormat('en-IN', {
                                        currency: 'INR',
                                        minimumFractionDigits: 2,
                                    }).format(Math.round(PendingDeposit))}
                                </Col>
                            </Row>
                            <FormGroup className="mb-2">
                                <Input
                                    name="incomeRemark"
                                    placeholder="Write Income Remarks"
                                    type="textarea"
                                    row={2}
                                    className="form-control-sm"
                                    value={incomeRemarks}
                                    onChange={e => handleIncomeremark(e.target.value)}
                                    invalid={incomeError}
                                />
                                {incomeError ? (
                                    <FormFeedback type="invalid">
                                        Remark is required
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                            <div style={{ display: "flex", justifyContent: "end", gap: "8px" }} className="text-end
">
                                <Button
                                    color="primary"
                                    className="btn btn-primary btn-sm "
                                    type="button"
                                    onClick={() => incomeTransactionSubmit()}
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="secondary"
                                    className="btn btn-primary btn-sm "
                                    type="button"
                                    onClick={() => incomeTransactionCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>

            <Modal
                isOpen={bookingItemStatusChangemodel}
                toggle={bookingItemStatusChangeToggle}
                centered={true}
                size="small"
                style={{ width: "550px" }}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Booking Products
                        </h5>
                        <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => handleCancle()}>
                        </button>
                    </div>
                    <ModalBody>
                        <BookingItemTableList
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCategoryList={true}
                            customPageSize={10}
                            status={BookingandStatus.statusId}
                            BookingandStatus={BookingandStatus}
                            isLoading={isLoading}
                            className="custom-header-css"
                        />
                        <div className="name-required" style={{ fontSize: "13px" }}> {isError ? errorMessage : ''} </div>
                        <div className="text-end mt-3">
                            <Button
                                color="secondary"
                                className="btn btn-primary me-2 btn-sm"
                                onClick={() => handleCancle()}
                            >
                                Cancle
                            </Button>
                            <>
                                {formSumitLoading ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                    >
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                        Loading
                                    </button>
                                ) : (
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-sm "
                                        type="button"
                                        onClick={() => handleSave()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}


export default CommonModel;

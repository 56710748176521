import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Input, Label } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import Select from "react-select";


const colourStyles = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid  #ced4da",
            minHeight: "35px",
            padding: "3px 10px",
            fontSize: "12px",
        }
        return updatedStyles
    },
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const SettingDataTable = ({
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    className,
    pagePerSize,
    isLoading,
    RoleName,
    vendorLookUp, vendeId, setVendeId,
    selectedUser, setSelectedUser
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: 3000,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )
    const handlesort = column => {
    }


    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }


    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    const [isMobile, setisMobile] = useState(window.innerWidth < 924)

    if (pagePerSize) {
        useEffect(() => {
            pagePerSize(pageSize)
        }, [pageSize, state.globalFilter])
    }
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={2} style={{ marginTop: "22px" }}>
                            {isGlobalFilter && (
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    isJobListGlobalFilter={isJobListGlobalFilter}
                                />
                            )}
                        </Col>
                        {RoleName.RoleName == "Admin" &&
                            <>
                                <Col md={4}>
                                    <Label htmlFor="">User</Label>
                                    <Select
                                        unstyled
                                        styles={colourStyles}
                                        value={selectedUser}
                                        onChange={(e) => {
                                            setSelectedUser(e)
                                            setVendeId(e?.value);
                                        }}
                                        options={vendorLookUp}
                                        className="select2-selection"
                                    />
                                </Col>
                            </>
                        }
                    </Row>
                </Col>
            </Row>


            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        className={` ${column.Header == 'Allow Vendor' && RoleName.RoleName == "Vendor" ? 'column-display' : ""} `}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className={`text-nowrap ${(cell.column.Header == 'Allow Vendor' && RoleName.RoleName == "Vendor") ? 'column-display' : ""} `}
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? ("Loading...") : ("No Record Found")}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

        </Fragment>
    )
}

SettingDataTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default SettingDataTable
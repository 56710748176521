import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
} from "reactstrap"
import ProductView from "./ProductView"
import DeleteModel from "../../../common/deleteModel/deleteModel"
import { dateFormat, dateTimeFormat, getFinancialYear } from "pages/Common"
import toast from "react-hot-toast"
import BookingTable from "pages/Reports/Booking/BookingTable"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import GetObjectsByValues from "_mock/Static_data"
import CommonModel from "../CommonModel"
import TransactionModel from "./TransactionModel"
import { decryptData } from "common/encrptDecryptforlogin"

function Booking() {
  document.title = `Bookings | ${localStorage.getItem("ShopName")}`
  const { deleteApi, getApi, postApi, putApi } = useApi()
  const navigate = useNavigate()
  let { pathname } = useLocation()

  // useStates
  const [tableData, setTableData] = useState([])
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [productViewId, setProductViewId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [statusCancel, setStatusCancel] = useState(false)
  const [status, setStatus] = useState(0)
  const [TotalRecords, setTotalRecords] = useState(0)
  const [dateDisble, setdateDisble] = useState(true)
  const [date, setdate] = useState([new Date(), new Date()])
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", bookingItemId: "", returned: false })
  const [flag, setFlag] = useState('')
  const [CommonModelFlag, setCommonModelFlag] = useState(false)
  const [pageSize, setpageSize] = useState(20)
  const [displayStart, setDisplayStart] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [transactionModelFlag, setTransactionModelFlag] = useState(false)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [billNo, setBillNo] = useState()
  const [fromAdmin, setFromAdmin] = useState({})

  useEffect(() => {
    if (localStorage.getItem("BacktoLogin")) {
      const handleDecrptdata = async () => {
        setFromAdmin(JSON.parse(await decryptData(localStorage.getItem("BacktoLogin"))));
      };
      handleDecrptdata()
    }
  }, [])

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setpageSize(pageSize)
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
  }

  const statusValue = status => {
    if (status) {
      setStatus(status)
    }
  }

  // handlers
  const toggle = () => {
    setModal(!modal)
  }

  // handlers
  const toggle1 = () => {
    setModal1(!modal1)
  }

  const bookingReportApi = () => {
    setIsLoading(true)
    postApi("Booking/booking-list", {
      fromDate: dateDisble ? null : dateFormat(date[0], "yyyy-MM-dd"),
      toDate: dateDisble ? null : dateFormat(date[1], "yyyy-MM-dd"),
      displayStart: displayStart,
      status: parseInt(status),
      pageSize: pageSize,
      searchText: localStorage.getItem("ContactNo") || searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        localStorage.removeItem("ContactNo")
        setIsLoading(false)
        setTableData(data.BookingList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // booking list API useEffect
  useEffect(() => {
    setStatusCancel(false)
    if (date[1] != undefined) {
      bookingReportApi()
    }
  }, [
    modal,
    statusCancel,
    status,
    date,
    searchText,
    displayStart,
    dateDisble,
    pageSize,
  ])

  //  Delete Booking handler
  const handleDelete = (bookingDeleteId) => {
    setIsLoading(true)
    if (bookingDeleteId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this booking permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          deleteApi(`booking/delete?id=${bookingDeleteId}&fromAdmin=${fromAdmin?.backToLogin || false}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== bookingDeleteId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Booking deleted successfully", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Booking",
                  text: data,
                })
              }
              setIsLoading(false)
            }
          )
        } else {
          setIsLoading(false)
        }
      })
    }
  }

  const toggleCommon = () => {
    if (CommonModelFlag) {
      setStatusCancel(true)
    }
    else {
      setStatusCancel(false)
    }
    setCommonModelFlag(!CommonModelFlag)
  }

  // Booking table in change status handler
  const handleBookingStatus = (bookingId, statusId, row) => {
    if (statusId == 4) {
      toggleCommon()
      setFlag("cancel")
      setBookingIdandStatusId({ bookingId: bookingId, statusId: statusId, bookingItemId: "", returned: false })
    } else if (statusId == 2) {
      toggleCommon()
      setFlag("booking")
      setBookingIdandStatusId({ bookingId: bookingId, statusId: statusId, bookingItemId: "", returned: false })
    } else if (statusId == 3) {
      setFlag("booking")
      toggleCommon()
      setBookingIdandStatusId({ bookingId: bookingId, statusId: statusId, bookingItemId: "", returned: true })
    } else if (statusId == 5) {
      setFlag("cancelHold")
      toggleCommon()
      setBookingIdandStatusId({ bookingId: bookingId, statusId: statusId, bookingItemId: "", returned: false })
    }
    else {
      setIsLoading(true)
      if ((bookingId, statusId)) {
        DeleteModel.confirmDeleteInquiry({
          title: "Are you sure you want to change status?",
          text: "If you change the status of this item, the status of all the product inside it will be changed.",
          isMobile,
        }).then(response => {
          if (response == true) {
            const bookingItemId = ""
            putApi("Booking/change-Status", {
              bookingId: bookingId == "" ? 0 : bookingId,
              bookingItemIds: bookingItemId,
              BookingStatus: parseInt(statusId),
              returnRemarks: "",
            }).then(({ data, status }) => {
              if (status === 200) {
                bookingReportApi()
                toast.success("Status change sucessfully", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                toast.error(data, {
                  style: {
                    fontSize: "15px",
                  },
                })
              }
              setIsLoading(false)
            })
          } else {
            setStatusCancel(true)
            setIsLoading(false)
          }
        })
      }
    }
    setIsLoading(false)
  }

  // settle transaction handler
  const handleSettleModel = (bookingId, billNo, statusId) => {
    toggleCommon()
    setFlag("settle")
    setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId, statusId: statusId })
  }

  const toggleTransaction = () => {
    setTransactionModelFlag(!transactionModelFlag)
  }

  // view all transaction handler
  const handleTransaction = (bookingId, billNo) => {
    if (bookingId) {
      setBillNo(billNo)
      setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
      toggleTransaction()
    }
  }

  const startDate = localStorage.getItem("FinancialYearFrom")
  const endDate = localStorage.getItem("FinancialYearTo")

  // Update  Booking handler
  const handleEditBooking = (bookingEditId, BookingDate) => {
    if (bookingEditId) {
      const SelectedFinancialYear = startDate?.split("-")[0] + "-" + (endDate?.split("-")[0])
      if (SelectedFinancialYear == getFinancialYear(BookingDate)) {
        navigate(routes.updatebooking, {
          state: {
            bookingEditId: bookingEditId,
          },
        })
      } else {
        toast.error("You cannot update the details due to a financial year mismatch.", {
          style: {
            fontSize: "15px",
          },
        })
      }
    }
  }

  // view button : Product View handler
  const handleProductView = (bookingViewId, billNo) => {
    if (bookingViewId) {
      setBillNo(billNo)
      setProductViewId(bookingViewId)
      toggle()
    }
  }

  // view booking
  const handleViewBooking = bookingId => {
    setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
    toggle1()
  }

  // Booking table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "4%",
        Cell: ({ row }) => {
          return <>
            {row.original.TotalProduct == 0 ? (
              <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
            ) : (
              <div
                style={{ cursor: "pointer", color: "#556ee6" }}
                onClick={() => {
                  handleViewBooking(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        },
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "6%",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "NetAmount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(Math.round(row?.original?.NetAmount) || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Advance
          </div>
        ),
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Advance || 0)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        width: "7%",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
          >
            {row.original.Remarks.length > 15
              ? `${row.original.Remarks.substr(0, 15)}...`
              : row.original.Remarks == ""
                ? "N/A"
                : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Total Product",
        accessor: "TotalProduct",
        width: "4%",
        Cell: ({ row }) => (
          <div
            className="text-primary , text-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleProductView(row.original.Id, row.original.BillNo)
            }}
          >
            {row.original.TotalProduct}
          </div>
        ),
      },
      {
        Header: "Booking Date",
        accessor: "BookingDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.BookingDate == ""
              ? "N/A"
              : dateTimeFormat(
                row.original.BookingDate,
                "dd/MM/yyyy HH:mm tt"
              )}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Status
          </div>
        ),
        width: "10%",
        // width: 100,
        accessor: "MinStatus",
        Cell: ({ row }) => {
          const { MinStatus } = row.original;
          let obj;
          if (MinStatus === 1) {
            obj = GetObjectsByValues(1, 2, 3, 4);
          } else if (MinStatus === 2) {
            obj = GetObjectsByValues(2, 3, 4);
          } else if (MinStatus === 3) {
            obj = GetObjectsByValues(3);
          } else {
            obj = GetObjectsByValues(4);
          }
          return <>
            <div
              className="textWrap"
              style={{
                height: "8px",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Input
                type="select"
                onChange={e =>
                  handleBookingStatus(
                    row.original.Id,
                    e.target.value,
                    row.original
                  )
                }
                disabled={row.original.MinStatus == 4 || row.original.MinStatus == 3}
                defaultValue={row.original.MinStatus}
                bsSize="sm"
                className={`${MinStatus == 1 ? "booking-status-booking" : MinStatus == 2 ? "deliverd-status-booking" : MinStatus == 3 ? "return-status-booking" : "cancle-status-booking"} form-control`}
              >
                {obj.map((item, index) => {
                  return (
                    <option key={index} className="bg-light text-dark" value={item.Value}>
                      {item.DisplayName}
                    </option>
                  )
                })}
              </Input>
            </div>
          </>
        }
        ,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "15%",
        accessor: "Action",
        disableFilters: true,
        Cell: ({ row }) => {

          const renderDisabledIcon = (iconClass, tooltipText) => (
            <li className="mytooltip">
              <i
                className={`${iconClass}`}
                style={{ cursor: "not-allowed", color: "#808080b3" }}
              ></i>
              <span className="mytext">{tooltipText}</span>
            </li>
          );

          return (
            <>
              {
                (
                  <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                    {row.original.TotalProduct === 0 || row.original.MinStatus != 1 ? (
                      <>
                        {renderDisabledIcon("fs-6 fas fa-edit btn-edit", `Edit`)}
                      </>
                    ) : (
                      <>
                        <li className="mytooltip">
                          <i
                            className="fs-6 fas fa-edit btn-edit"
                            id={`edittooltip${row.original.Id}`}
                            onClick={() => handleEditBooking(row.original.Id, row.original.BookingDate)}
                          ></i>
                          <span className="mytext">Edit</span>
                        </li>
                      </>
                    )}

                    {row.original.MinStatus === 4 ? (
                      <>
                        {renderDisabledIcon("fs-6 fas fa-print btn-edit", `Print`)}
                      </>
                    ) : (
                      <>
                        <li className="mytooltip">
                          <i
                            className={`fs-6 fas fa-print`}
                            style={{ color: "#f39900", cursor: "pointer" }}
                            onClick={() => handlePrint(row.original.Id)}
                          ></i>
                          <span className="mytext">Print</span>
                        </li>
                      </>
                    )}

                    {row.original.MinStatus === 4 ? (
                      <>
                        {renderDisabledIcon("fs-6 fas fa-download", `Download`)}
                      </>
                    ) : (
                      <>
                        <li className="mytooltip">
                          <i
                            className={`fs-6 fas fa-download`}
                            style={{ color: "#90499c", cursor: "pointer" }}
                            onClick={() => handleDownload(row.original.Id, row.original.BillNo)}
                          ></i>
                          <span className="mytext">Download</span>
                        </li>
                      </>
                    )}

                    {row.original.MinStatus === 4 || row.original.TotalProduct === 0 ? (
                      <>
                        {renderDisabledIcon("fs-5 fab fa-whatsapp", `WhatsApp`)}
                      </>
                    ) : (
                      <>
                        <li className="mytooltip">
                          <i
                            className="fab fa-whatsapp"
                            style={{ color: "green", cursor: "pointer", fontSize: "15px" }}
                            onClick={() => whatapp(row.original.ContactNo1, row.original.Id)}
                          ></i>
                          <span className="mytext">WhatsApp</span>
                        </li>
                      </>
                    )}

                    <li className="mytooltip">
                      <i
                        className={`fs-6 fas fas fa-balance-scale`}
                        style={{ color: "#90499c", cursor: "pointer" }}
                        onClick={() => handleSettleModel(row.original.Id, row.original.BillNo, row.original.MinStatus)}
                      ></i>
                      <span className="mytext">Settlement</span>
                    </li>

                    <li className="mytooltip">
                      <i
                        className="fas fa-handshake"
                        style={{ color: "#556ee6", cursor: "pointer" }}
                        onClick={() => handleTransaction(row.original.Id, row.original.BillNo)}
                      ></i>
                      <span className="mytext">Transaction</span>
                    </li>

                    {row.original.MinStatus === 1 || fromAdmin?.backToLogin ? (
                      <>
                        <li className="mytooltip">
                          <i
                            className="fs-6 fas fa-trash-alt btn-delete"
                            id={`deleteTooltip${row.original.Id}`}
                            onClick={() => handleDelete(row.original.Id)}
                          ></i>
                          <span className="mytext">Delete</span>
                        </li>
                      </>
                    ) : (
                      <>
                        {renderDisabledIcon("fs-6 fas fa-trash-alt btn-delete", `Delete`)}
                      </>
                    )}
                  </ul >
                )
              }
            </>
          )
        },
      },
    ],
    [tableData]
  )

  const handleDownload = async (id, Billno) => {
    setIsLoading(true)
    getApi(`Report/booking-invoice?bookingId=${id}`).then(({ data, status }) => {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `invoice_${Billno}.pdf`;

      link.click();

      window.URL.revokeObjectURL(link.href);
      setIsLoading(false)

    })
  };

  const handlePrint = async (id) => {
    setIsLoading(true)
    getApi(`Report/booking-invoice?bookingId=${id}`).then(({ data, status }) => {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url);

      setIsLoading(false)
      newWindow.addEventListener('load', () => {
        newWindow.print();
      });
    })
  };

  const whatapp = async (num, id) => {
    setIsLoading(true)
    postApi("Setting/setting-by-keys", {
      Key: ["WHATSAPP_MASSAGE"],
      userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
    })
      .then(async ({ data, status }) => {
        if (status === 200) {
          let { data: { InvoiceDetail, ProductList } } = await getApi(`Booking/invoice-detail?id=${id}`)
          let {
            Advance,
            BillNo,
            BillNotes,
            Discount,
            CustomerName,
            ShopName,
            Deposit,
            DisplayDeposit,
            ShopAddress,
            NetAmount,
          } = InvoiceDetail
          setIsLoading(false)
          var Massage = data[0].Value
            .replaceAll("{SHOP_NAME}", ShopName)
            .replaceAll("{BILL_NO}", BillNo)
            .replaceAll("{CUSTOMER_NAME}", CustomerName)
            .replaceAll("{TOTAL_RENT}", NetAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }))
            .replaceAll("{DISCOUNT}", Discount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }))
            .replaceAll("{ADVANCE}", Advance.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }))
            .replaceAll(
              "{PENDING_AMOUNT}",
              (parseInt(NetAmount) - parseInt(Advance)).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
            )
            .replaceAll("{SECURITY}", DisplayDeposit.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }) + (Deposit == 0 ? "" : Deposit > 0 ? " (Paid)" : " (Unpaid)"))
            .replaceAll("{BILL_NOTES}", BillNotes)
            .replaceAll("{ADDRESS}", ShopAddress)
            .replaceAll("<br>", '\n')

          var products = ''
          for (let i = 0; i < ProductList.length; i++) {
            var product = `{INDEX}. Product: {PRODUCT_CODE}, {PRODUCT_NAME}
      Delivery Date: {DELIVERY_DATE}
      Return Date: {RETURN_DATE}
      Rent: {RENT}
      TotalRent: {TOTALRENT}\n\n`
            products += product
              .replaceAll("{INDEX}", (i + 1))
              .replaceAll("{PRODUCT_CODE}", ProductList[i].ProductCode)
              .replaceAll("{PRODUCT_NAME}", `(${ProductList[i].ProductTitle})`)
              .replaceAll(
                "{DELIVERY_DATE}",
                dateFormat(ProductList[i].DeliveryDate.split("T")[0], "dd/MM/yyyy")
              )
              .replaceAll(
                "{RETURN_DATE}",
                dateFormat(ProductList[i].ReturnDate.split("T")[0], "dd/MM/yyyy")
              )
              .replaceAll("{RENT}", ProductList[i].NetRent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }))
              .replaceAll("{TOTALRENT}", ProductList[i].TotalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }))
          }
          Massage = Massage.replaceAll("{ITEMS}", products)
          window.open(
            // `https://wa.me/+91${num}?text=${encodeURIComponent(`${message}`)}`,
            `https://wa.me/+91${num}?text=${encodeURIComponent(Massage)}`,
            "_blank",
            "width=1000,height=600,menubar=no,toolbar=no"
          );
        }
        else {
          setIsLoading(false)
        }
      })
  }

  return (
    <div className="page-content background">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <BookingTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={20}
              isLoading={isLoading}
              pagePerSize={pagePerSize}
              statusValue={statusValue}
              TotalRecords={TotalRecords}
              className="custom-header-css"
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setdate={setdate}
            // writeDataToExcel={writeDataToExcel}
            />

            <Modal isOpen={modal} toggle={toggle} centered={true} size="xl" style={{ width: "1016px" }}>
              <div className="modal-content">
                <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                  Products / Bill No: {billNo}
                </ModalHeader>
                <ModalBody>
                  <ProductView
                    productViewId={productViewId}
                    modal={modal}
                    setModal={setModal}
                  />
                </ModalBody>
              </div>
            </Modal>

            <InvoiceDetalis
              toggle={toggle1}
              modal={modal1}
              bookingId={BookingIdandStatusId.bookingId}
            />
            {CommonModelFlag &&
              <CommonModel
                modal={CommonModelFlag}
                toggle={toggleCommon}
                BookingandStatus={BookingIdandStatusId}
                flag={flag}
              />
            }

            <TransactionModel
              modal={transactionModelFlag}
              toggle={toggleTransaction}
              BookingandStatus={BookingIdandStatusId}
              billNo={billNo}
            />


          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Booking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Booking
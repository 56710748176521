import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SystemLogTable from "./SystemLogTable"
import { dateFormat, dateTimeFormat } from "pages/Common"

function SystemLog() {
  const { getApi, putApi, postApi } = useApi()
  document.title = `System Logs | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()

  // useStates
  const [modal, setModal] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [date, setdate] = useState([new Date(), new Date()])
  const [pageSize, setpageSize] = useState(20)
  const [displayStart, setDisplayStart] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [TotalRecords, setTotalRecords] = useState(0)
  const [refType, setRefType] = useState(1)
  const [jsonData, setJsonData] = useState()
  const [billNo, setBillNo] = useState()
  const [jsonType, setJsonType] = useState(0)
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("authUser")).Id)
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  const [selectedUser, setSelectedUser] = useState()
  const [vendorLookUp, setvendorLookUp] = useState([])

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setpageSize(pageSize)
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
  }

  useEffect(() => {
    if (RoleName.RoleName == "Admin") {
      getApi("User/vendor-lookup").then(({ data, status }) => {
        if (status === 200) {
          const itemData = data.map(item => ({
            label: item.Value,
            value: item.Key,
          }));
          setSelectedUser(itemData[0])
          setvendorLookUp(itemData)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }, [])

  // setting list api
  const systemLogApi = () => {
    setIsLoading(true)
    postApi("Common/system-logs", {
      UserId: userId,
      refType: parseInt(refType),
      fromDate: dateFormat(date[0], "yyyy-MM-dd"),
      toDate: dateFormat(date[1], "yyyy-MM-dd"),
      displayStart: displayStart,
      pageSize: pageSize,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data?.SystemLogsList)
        setTotalRecords(data?.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // setting list api useEffect
  useEffect(() => {
    if (date[1] != undefined) {
      systemLogApi()
    }
  }, [displayStart, refType, date, pageSize, searchText, userId])

  const toggle = () => {
    setModal(!modal)
  }

  const handleIconClickJSONConvert = (JSONData, billNo, jsonType) => {
    setJsonData(JSON.parse(JSONData))
    setBillNo(billNo)
    setJsonType(jsonType)
    toggle()
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Ref. Id",
        accessor: "RefId",
        width: "12%",
      },
      {
        Header: "Bill No.",
        accessor: "RefNo",
        width: "12%",
        Cell: ({ row }) =>
        (
          <p className=" mb-0">
            {row.original.RefNo == null
              ? "N/A"
              :
              row.original.RefNo}
          </p>
        ),
      },
      {
        Header: "Action Type",
        accessor: "ActionName",
        width: "8%",
      },
      {
        Header: "Change Count",
        accessor: "SubRefId",
        width: "5%",
        Cell: ({ row }) => (
          <p
            style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
            className="mb-0"
          >
            {row.original.SubRefId}
          </p>
        ),
      },
      {
        Header: "Modify on",
        accessor: "CreatedOn",
        width: "18%",
        Cell: ({ row }) =>
        (
          <p className=" mb-0">
            {row.original.CreatedOn == ""
              ? "N/A"
              : dateTimeFormat(
                row.original.CreatedOn,
                "dd/MM/yyyy HH:mm tt"
              )}
          </p>
        ),
      },
      {
        Header: "Json Data-1",
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          >
            {(refType == 1 || refType == 2 || refType == 3) ? "Bill Data" : "Data"}
          </div>
        ),
        accessor: "JsonData1",
        Cell: ({ row }) =>
        (
          <p
            style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
            className="mb-0"
          >
            <div className="">
              {row.original.JsonData1 == '' ? "N/A" :
                <i
                  title="View"
                  onClick={() => handleIconClickJSONConvert(row.original.JsonData1, row.original.RefNo, 1)}
                  className="fas fa-eye"
                  style={{ fontSize: "14px", marginLeft: "5px", cursor: "pointer" }}
                ></i>
              }
            </div>
          </p>
        ),
      },
      {
        Header: "Json Data-2",
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          >
            {(refType == 1 || refType == 2 || refType == 3) ? "Product Data" : "Other Data"}
          </div>
        ),
        accessor: "JsonData2",
        Cell: ({ row }) => (
          <div
            style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
            className="mb-0"
          >
            {row.original.JsonData2 == '' ? "N/A" :
              <i
                title="View"
                onClick={() => handleIconClickJSONConvert(row.original.JsonData2, row.original.RefNo, 2)}
                className="fas fa-eye"
                style={{ fontSize: "14px", marginLeft: "5px", cursor: "pointer" }}
              ></i>
            }
          </div>
        ),
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SystemLogTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={20}
              isLoading={isLoading}
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              className="custom-header-css"
              date={date}
              setdate={setdate}
              refType={refType}
              setRefType={setRefType}
              userId={userId}
              setUserId={setUserId}
              vendorLookUp={vendorLookUp}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              RoleName={RoleName}
            />
            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "450px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  Json Data-{jsonType} / {billNo}
                </ModalHeader>
                <ModalBody>
                  <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word", fontSize: "13px" }}>
                    {JSON.stringify(jsonData, null, 2)}
                  </pre>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

SystemLog.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SystemLog

/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Container,
  FormFeedback,
  Table,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Label,
  InputGroup,
  CardBody,
} from "reactstrap"
import CreatableSelect from "react-select/creatable"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Tbody, Th, Tr } from "react-super-responsive-table"
import toast from "react-hot-toast"
import TableList from "components/Common/TableList"
import useApi from "common/ApiMiddlewere"
import { dateFormat } from "pages/Common"
import { NavLink, useNavigate } from "react-router-dom"
import classnames from "classnames"
import AddCartPage from "./AddCartPage"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useRef } from "react"
import uploadImage from "assets/images/uploadImage.png"
import DeleteModel from "common/deleteModel/deleteModel"
import { useDispatch, useSelector } from "react-redux"
import { addToCartRequest } from "store/addtocart/action"
import { routes } from "routes/path"

const colourStyles = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid  #ced4da",
      minHeight: "28px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}
const colourStyles1 = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid #f46a6a",
      minHeight: "28px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}



function Avaiblity() {
  const { getApi, postApi } = useApi()
  let { pathname } = useLocation()
  const scrollRef = useRef(null)
  const navigate = useNavigate()
  const inputRef = useRef()
  const [falgForTimeSlotevalidation, setfalgForTimeSlotevalidation] = useState(false)

  const dispatch = useDispatch();
  const addToCartStatus = useSelector(state => state.ReduxData.addToCartStatus);

  // useStates
  const [screeLoad, setscreeLoad] = useState(true)
  const [bookingAvailableList, setBookingAvailableList] = useState([])
  const [cartAvailableList, setCartAvailableList] = useState([])
  const [productViewHistoryDetail, setProductViewHistoryDetail] = useState([])
  const [bookingDataStatus, setBookingDataStatus] = useState()
  const [fromDate, setFromDate] = useState("")
  const [data, setData] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [TimeSolteDetails, setTimeSolteDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isChangeTab, setIsChangeTab] = useState(false)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [formSumitLoading2, setformSumitLoading2] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalAvailability, setModalAvailability] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modalViewHistory, setModalViewHistory] = useState(false)
  const [cartConfirm, setCartConfirm] = useState(false)
  const [cartButton, setCartButton] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState("")
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isIpad, setIpad] = useState(window.innerWidth < 924)
  const [productDetails, setproductDetails] = useState({
    ProductName: "N/A",
    ProductImage: "",
    Rent: 0,
    SizeName: "N/A",
    ColorName: "N/A",
    Qty: 0,
  })
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [passwordShow, setPasswordShow] = useState(false)
  const [subAdminPasswordLoading, setSubAdminPasswordLoading] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [enableProductStock, setEnableProductStock] = useState(false)
  const [createBillLoading, setCreateBillLoading] = useState(false)
  const [cartCustomer, setCartCustomer] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [customerNameRequired, setCustomerNameRequired] = useState(false)
  const [roleName, setRoleName] = useState(JSON.parse(localStorage.getItem("authUser")).RoleName)
  const [customerFindByContactNo, setCustomerFindByContactNo] = useState(false)
  const [oldBookingId, setOldBookingId] = useState(0)

  const toggleAccordion = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  useEffect(() => {
    setscreeLoad(true)
    getApi("TimeSlot/time-slot-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        setTimeSolteDetails(data)
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
    getApi("Cart/cart-customer-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        setCartCustomer(data)
        data.map(item => {
          options.push({
            label: item.Value,
            value: item.Value,
          })
        })
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    postApi("Setting/setting-by-keys", {
      key: ["TIME_SLOT_MANDATORY", "ENABLE_PRODUCT_STOCK"],
      userId: JSON.parse(localStorage.getItem("authUser")).RoleName
        == "Salesman" ? JSON.parse(localStorage.getItem("authUser")).VendorId : (JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id),
    }).then(({ data, status }) => {
      if (status == 200) {
        setfalgForTimeSlotevalidation(data[0].Value == "1");
        setEnableProductStock(data[1].Value == "1")
      }
    })
  }, [])

  useEffect(() => {
    if (value == null) {
      setInputValue("")
    }
  }, [value])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleAvailability = () => {
    setModalAvailability(!modalAvailability)
  }

  const toggleViewHistory = () => {
    setModalViewHistory(!modalViewHistory)
  }

  const handleViewProductHistory = (productCode) => {
    if (productCode) {
      if (productCode.trim().toLowerCase() === (JSON.parse(localStorage.getItem("authUser")).UserName).toLowerCase()) {
        setIsFocus(true)
        closePopup()
      } else {
        postApi("Report/product-history", {
          productCode: productCode.trim(),
          pendingForReturn: false,
        }).then(({ data, status }) => {
          if (status === 200) {
            setProductViewHistoryDetail(data?.ProductHistoryList)
            toggleViewHistory()
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    } else {
      toast.error("Please fill product code", {
        // icon: "⚠️",
        style: {
          fontSize: "15px",
        },
      })
    }
  }

  const handleformDate = e => {
    validation.handleChange(e)
    setFromDate(e.target.value)
  }

  const handleToDate = e => {
    validation.handleChange(e)
  }

  const handaleConfirm = () => {
    DeleteModel.confirmDeleteInquiry({
      title: "Are you sure to procced?",
      text: "",
      isMobile,
    }).then(response => {
      if (response == true) {
        setCartConfirm(true)
        setIsChangeTab(false)
        addtoCartValidation.handleSubmit()
        toggle()
      } else {
        toggle()
      }
    })
  }

  const handaleConfirmAvailability = () => {
    setCartButton(true)
    toggleAvailability()
  }

  // avalability validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Code: "",
      formDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
      productQty: 1,
      enableProductStock: enableProductStock,
    },
    validationSchema: Yup.object({
      Code: Yup.string().required("Code is required"),
      formDate: Yup.string().required("Delivery Date is required"),
      toDate: Yup.string().required("Return Date is required"),
      // productQty: Yup.number().min(1, "Minimum 1 qty is required").required("The qty is required"),
      ...(enableProductStock && {
        productQty: Yup.number().min(1, "Minimum 1 qty is required").required("The qty is required"),
      }),
    }),
    onSubmit: values => {
      setformSumitLoading2(true)
      setIsChangeTab(false)

      if (values) {
        setData(values)
        const differenceInMilliseconds = new Date(values.toDate) - new Date(values.formDate);
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        // Delivery & return product max days alert message
        postApi("Setting/setting-by-keys", {
          key: ["DELIVERY_TO_RETURN_DATE_MAX_DAYS"],
          userId: JSON.parse(localStorage.getItem("authUser")).RoleName
            == "Salesman" ? JSON.parse(localStorage.getItem("authUser")).VendorId : (JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id),
        }).then(({ data, status }) => {
          if (status == 200) {
            if (parseInt(data[0]?.Value) <= differenceInDays) {
              setBookingAvailableList("")
              setBookingDataStatus("")
              setCartButton(false)
              DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: `
          <div>You want to book this product for more than <strong>${data[0]?.Value} days</strong>
          Delivery: <strong>${dateFormat(
                  values.formDate,
                  "dd/MM/yyyy"
                )}</strong>
          Return: <strong>${dateFormat(
                  values.toDate,
                  "dd/MM/yyyy"
                )}</strong></div>
        ` ,
                isMobile,
              }).then(response => {
                setCartButton(false)
                if (response == true) {
                  // Booking check availability
                  postApi("Booking/check-availablility", {
                    productCode: values.Code.trim(),
                    fromDate: values.formDate,
                    toDate: values.toDate,
                    BookingItemIds: "",
                    qty: values.productQty || 1,
                  }).then(({ data, status }) => {
                    setInputValue("")
                    setformSumitLoading2(false)
                    if (scrollRef.current) {
                      scrollRef.current.scrollIntoView({ behavior: "smooth" })
                    }
                    if (status === 200) {
                      setproductDetails({
                        ProductName: data.ProductName,
                        ProductImage: data.ProductImage,
                        Rent: data?.Rent || 0,
                        SizeName: data.SizeName,
                        ColorName: data.ColorName,
                        Qty: data.Qty || 0,
                      })
                      setModalAvailability(data.BookingAvailabilityList.length > 0 &&
                        data.Status === 3)
                      setBookingAvailableList(data.BookingAvailabilityList)
                      setBookingDataStatus(data.Status)
                    } else {
                      setproductDetails({
                        ProductName: "N/A",
                        ProductImage: "",
                        Rent: 0,
                        SizeName: "N/A",
                        ColorName: "N/A",
                        Qty: 0,
                      })
                      setModalAvailability(false)
                      setBookingAvailableList("")
                      setBookingDataStatus("")
                      setCartButton(false)
                      toast.error(data, {
                        // icon: "⚠️",
                        style: {
                          fontSize: "15px",
                        },
                      })
                    }
                  })
                } else {
                  setformSumitLoading2(false)
                }
              })
            } else {
              // Booking check availability
              postApi("Booking/check-availablility", {
                productCode: values.Code.trim(),
                fromDate: values.formDate,
                toDate: values.toDate,
                BookingItemIds: "",
                qty: values.productQty || 1,
              }).then(({ data, status }) => {
                setInputValue("")
                setformSumitLoading2(false)
                if (scrollRef.current) {
                  scrollRef.current.scrollIntoView({ behavior: "smooth" })
                }
                if (status === 200) {
                  setproductDetails({
                    ProductName: data.ProductName,
                    ProductImage: data.ProductImage,
                    Rent: data?.Rent,
                    SizeName: data.SizeName,
                    ColorName: data.ColorName,
                    Qty: data.Qty || 0,
                  })
                  setModalAvailability(data.BookingAvailabilityList.length > 0 &&
                    data.Status === 3)
                  setBookingAvailableList(data.BookingAvailabilityList)
                  setBookingDataStatus(data.Status)
                } else {
                  setModalAvailability(false)
                  setproductDetails({
                    ProductName: "N/A",
                    ProductImage: "",
                    Rent: 0,
                    SizeName: "N/A",
                    ColorName: "N/A",
                    Qty: 0,
                  })
                  setBookingAvailableList("")
                  setBookingDataStatus("")
                  setCartButton(false)
                  toast.error(data, {
                    // icon: "⚠️",
                    style: {
                      fontSize: "15px",
                    },
                  })
                }
              })
            }
          }
        })
      }
    },
  })

  // Add product in cart
  const addtoCartValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productCode: data?.Code,
      deliveryDate: data?.formDate,
      returnDate: data?.toDate,
      customerName: inputValue ? inputValue : value?.label,
      deliveryTimeSlot: "",
      returnTimeSlot: "",
      qty: data?.productQty || 1
    },
    validationSchema: Yup.object(falgForTimeSlotevalidation ? {
      deliveryTimeSlot: Yup.string().required("The Delivery time is required"),
      returnTimeSlot: Yup.string().required("The Return time is required"),
    } : {
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (
        values.productCode == "" ||
        values.customerName == "" ||
        values.customerName == undefined
      ) {
        setformSumitLoading(false)
        setIsError(true)
        setErrorMessage("Customer name is required")
      } else {
        postApi("Cart/add-to-cart", {
          name: values.customerName,
          productCode: values.productCode,
          deliveryDate: values.deliveryDate,
          deliveryTimeSlot: values.deliveryTimeSlot,
          returnDate: values.returnDate,
          returnTimeSlot: values.returnTimeSlot,
          confirmByVendor: cartConfirm,
          qty: values.qty,
          oldBookingId: oldBookingId,
        }).then(({ data, status }) => {
          setIsError(false)
          setformSumitLoading(false)
          if (status == 200 && data.Status == 1) {
            setValue("")
            setInputValue("")
            setBookingAvailableList([])
            setBookingDataStatus("")
            setCartConfirm(false)
            setCartButton(false)
            setIsChangeTab(true)
            setproductDetails({
              ProductName: "N/A",
              ProductImage: "",
              Rent: 0,
              SizeName: "N/A",
              ColorName: "N/A",
              Qty: 0,
            })
            customerLookup()
            dispatch(addToCartRequest(addToCartStatus + 1));
            toast.success("The product successfully added to the cart.", {
              style: {
                fontSize: "15px",
              },
            })
            validation.handleReset()
            addtoCartValidation.handleReset()
          } else if (status == 200 && data.Status == 2) {
            setIsChangeTab(true)
            setCartButton(false)
            customerLookup()
            setproductDetails({
              ProductName: "N/A",
              ProductImage: "",
              Rent: 0,
              SizeName: "N/A",
              ColorName: "N/A",
              Qty: 0,
            })
            toast.error(data.ErrorMessage, {
              style: {
                fontSize: "15px",
              },
            })
          } else {
            setproductDetails({
              ProductName: "N/A",
              ProductImage: "",
              Rent: 0,
              SizeName: "N/A",
              ColorName: "N/A",
              Qty: 0,
            })
            customerLookup()
            setIsChangeTab(true)
            setCartButton(false)
            setCartAvailableList(data.CartAvailabilityList)
            toggle()
          }
        })
      }
    },
  })

  // Function to close the popup
  const closePopup = () => {
    subAdminValidation.handleReset()
    setModal2(!modal2);
  };

  const subAdminValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Password: "",
      Username: '',
    },
    validationSchema: Yup.object({
      Username: Yup.string()
        .required("The username is required"),
      Password: Yup.string()
        .required("The password is required")
    }),
    onSubmit: values => {
      if (values) {
        setSubAdminPasswordLoading(true)
        postApi("User/login-as-subadmin", {
          username: values.Username.trim(),
          password: values.Password.trim(),
        }).then(
          ({ data, status }) => {
            if (status === 200) {
              localStorage.setItem("authUser", JSON.stringify(data))
              navigate(routes.dashboard)
              setModal2(!modal2)
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
            setSubAdminPasswordLoading(false)
          }
        )
      }
    },
  })

  // table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Contact No.1",
        accessor: "ContactNo1",
        width: "8%",
      },
      {
        Header: "Contact No.2",
        accessor: "ContactNo2",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ContactNo2 == null ? "N/A" : row.original.ContactNo2}
          </p>
        ),
      },

      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    []
  )

  const columns1 = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Salesman Name",
        accessor: "SalesmanName",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate,ReturnTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    []
  )

  const columns2 = useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Bill No.
          </div>
        ),
        accessor: "BillNo",
        width: "5%",
      },
      // {
      //   Header: () => (
      //     <div
      //       style={{
      //         textAlign: "left",
      //       }}
      //     >
      //       Code
      //     </div>
      //   ),
      //   accessor: "ProductCode",
      //   width: "5%",
      //   Cell: ({ row }) => {
      //     return <div>{productDetails?.ProductCode}</div>
      //   },
      // },
      // {
      //   Header: () => (
      //     <div
      //       style={{
      //         textAlign: "left",
      //       }}
      //     >
      //       Booking Date
      //     </div>
      //   ),
      //   accessor: "BookingDate",
      //   width: "7%",
      //   Cell: ({ row }) => {
      //     return <div>{dateFormat(row.original.BookingDate, "dd/MM/yyyy")}</div>
      //   },
      // },
      {
        Header: "Status",
        accessor: "Status",
        width: "7%",
        Cell: ({ row }) => {
          return <div>{row.original.Status == 1 ? "Booked" : row.original.Status == 2 ? "Delivered" : row.original.Status == 3 ? "Return" : "Cancel"}</div>
        },
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Amount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Qty
          </div>
        ),
        accessor: "Qty",
        width: "4%",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {row?.original?.Qty || 1}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Customer Name
          </div>
        ),
        accessor: "CustomerName",
        Cell: ({ row }) => {
          return <div>{row.original.CustomerName}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Contact No.
          </div>
        ),
        width: "7%",
        accessor: "CustomerContactNo",
        Cell: ({ row }) => {
          return <div>{row.original.CustomerContactNo}</div>
        },
      },
    ],
    [productViewHistoryDetail]
  )

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const createOption = label => ({
    label,
    value: label?.toLowerCase().replace(/\W/g, ""),
  })

  const handleApiCall = async (contactNo) => {
    if (contactNo) {
      postApi("Customer/find-customer-by-no", {
        userId: 0,
        contactNo: contactNo,
      }).then(({ data, status }) => {
        if (status === 200) {
          if (data.OldBookingId === 0) {
            setIsError(true);
            setErrorMessage("Contact number is not found");
          } else {
            setIsError(false);
            setOldBookingId(data.OldBookingId);
            const newOption = createOption(data?.Name);
            setOptions(prev => [...prev, newOption]);
            setValue(newOption);
          }
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          });
        }
      });
    }
  };

  const handleValidation = (inputValue) => {
    if (inputValue.length !== 10) {
      setIsError(true);
      setErrorMessage("Contact number must be exactly 10 digits.");
      return true;
    } else {
      setIsError(false);
      return false;
    }
  };

  const handleCreate = (inputValue) => {
    if (customerFindByContactNo) {
      const newOption = createOption(inputValue);
      setValue(newOption);
      if (handleValidation(inputValue)) {
        setIsError(true);
        setErrorMessage("Contact number must be exactly 10 digits.");
      } else {
        setIsError(false)
        handleApiCall(inputValue);
      }
    } else {
      addNewOption(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab" && customerFindByContactNo) {
      const inputValue = event.target.value;
      const newOption = createOption(inputValue);
      setValue(newOption);
      if (handleValidation(inputValue)) {
        setIsError(true);
        setErrorMessage("Contact number must be exactly 10 digits.");
      } else {
        setIsError(false)
        handleApiCall(inputValue);
      }
    }
  };

  const addNewOption = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setValue(newOption);
      setOptions(prev => [...prev, newOption]);
      setIsLoading(false);
    }, 1000);
  };

  const handleCreateBooking = () => {
    if (selectedCustomer) {
      setCreateBillLoading(true)
      navigate(routes.createbooking, {
        state: {
          cartName: selectedCustomer,
        },
      })
    } else {
      setCustomerNameRequired(true)
    }
  }

  const customerLookup = () => {
    getApi("Cart/cart-customer-lookup").then(({ data, status }) => {
      if (status === 200) {
        setCartCustomer(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  document.title = `Available | ${localStorage.getItem("ShopName")}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />

          <Card>
            <Row>
              <Col md={4}>
                <Nav
                  tabs
                  className="nav-tabs-custom nav-justified"
                  style={{ padding: "2px 10px" }}
                >
                  <NavItem
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "active nav-link": customActiveTab === "1",
                      })}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-calendar-check"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        Check Availablility
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "active nav-link": customActiveTab === "2",
                      })}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-shopping-cart"></i>
                      </span>
                      <span className="d-none d-sm-block">Cart List</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      onReset={e => {
                        e.preventDefault
                        validation.handleReset()
                        return false
                      }}
                    >

                      <Row>
                        <Col md={9}>
                          <Row>
                            <Col md={enableProductStock ? 3 : 4} className="mb-2">
                              <label htmlFor="code" className="required form-label">
                                Code
                              </label>
                              <Row>
                                <InputGroup style={{ paddingRight: "0px" }}>
                                  <Input
                                    name="Code"
                                    placeholder="Code"
                                    type="text"
                                    className="form-control-sm"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Code || ""}
                                    invalid={
                                      validation.touched.Code && validation.errors.Code ? true : false
                                    }
                                  />
                                  <div
                                    className="input-group-append"
                                  >
                                    <button
                                      title="View History"
                                      type="button"
                                      style={{
                                        width: "28px",
                                        color: "#3834349c",
                                        cursor: "pointer",
                                        minHeight: "27px",
                                        border: "1px solid  #ced4da",
                                        backgroundColor: "#f8f9fa",
                                      }}
                                      onClick={() => handleViewProductHistory(validation.values.Code)}
                                    >
                                      <span>
                                        <i className="fas fa-history" style={{ marginTop: "5px" }} />
                                      </span>
                                    </button>
                                  </div>
                                  {validation.touched.Code && validation.errors.Code ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.Code}
                                    </FormFeedback>
                                  ) : null}

                                </InputGroup>
                              </Row>
                            </Col>
                            {enableProductStock &&
                              <Col md="1">
                                <div className="mb-2">
                                  <Label className="form-label">
                                    Qty
                                  </Label>
                                  <Input
                                    name="productQty"
                                    className="form-control-sm"
                                    placeholder="1"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={parseInt(validation.values.productQty)}
                                    invalid={
                                      (validation.touched.productQty &&
                                        validation.errors.productQty)
                                        ? true
                                        : false
                                    }
                                  />
                                  {(validation.touched.productQty &&
                                    validation.errors.productQty) ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.productQty}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>}
                            <Col md={4} className="mb-2">
                              <label
                                htmlFor="delivery"
                                className="required form-label"
                              >
                                Delivery
                              </label>
                              <Input
                                name="formDate"
                                placeholder="date placeholder"
                                type="date"
                                // min={new Date().toISOString().slice(0, 10)}
                                className="form-control-sm"
                                onChange={e => {
                                  handleformDate(e)
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.formDate}
                                invalid={
                                  validation.touched.formDate &&
                                    validation.errors.formDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.formDate &&
                                validation.errors.formDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.formDate}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={4} className="mb-2">
                              <label
                                htmlFor="return"
                                className="required form-label"
                              >
                                Return
                              </label>
                              <Input
                                name="toDate"
                                type="date"
                                min={fromDate}
                                className="form-control-sm"
                                onChange={e => {
                                  handleToDate(e)
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.toDate || ""}
                                invalid={
                                  validation.touched.toDate &&
                                    validation.errors.toDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.toDate &&
                                validation.errors.toDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.toDate}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={isIpad ? 3 : 2}
                          className="mb-2 available_button check_button Button_aline"
                        >
                          <>
                            {formSumitLoading2 ? (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                style={{ width: "100%" }}
                              >
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                Loading
                              </button>
                            ) : (
                              <Button
                                color="primary"
                                className="btn btn-primary btn-sm mt-1"
                                style={{ width: "100%" }}
                                // type="submit"
                                onClick={() => {
                                  validation.handleSubmit();
                                  setCartButton(false)
                                }}
                              >

                                Check Available
                              </Button>
                            )}
                          </>
                        </Col>

                        <Col md={12}>
                          <Row className="mt-2">
                            <Col md={3} className="mb-2">
                              <div className=" d-flex gap-2">
                                <label
                                  className="required form-label"
                                >
                                  Customer
                                </label>
                                <input
                                  type="radio"
                                  value={false}
                                  id="name"
                                  className="form-check-input"
                                  onClick={() => { setCustomerFindByContactNo(false), setValue(''), setIsError(false) }}
                                  checked={customerFindByContactNo == false}
                                />
                                <Label className="form-check-label font-size-13" style={{ marginRight: "7px" }} htmlFor="name">
                                  Name
                                </Label>
                                <input
                                  type="radio"
                                  value={true}
                                  id="contactNo"
                                  className="form-check-input"
                                  onClick={() => { setCustomerFindByContactNo(true), setValue(''), setIsError(false) }}
                                  checked={customerFindByContactNo == true}
                                />
                                <Label className="form-check-label font-size-13" htmlFor="contactNo">
                                  Mobile
                                </Label>
                              </div>
                              <CreatableSelect
                                name="customerName"
                                unstyled
                                styles={isError ? colourStyles1 : colourStyles}
                                isClearable
                                isDisabled={isLoading}
                                isLoading={isLoading}
                                onKeyDown={event => handleKeyDown(event)}
                                // onInputChange={(inputValue) => handleInputChange(inputValue)}
                                onChange={newValue => {
                                  setValue(newValue), setIsError(false)
                                }}
                                onBlur={validation.handleBlur}
                                onCreateOption={handleCreate}
                                options={options}
                                value={value}
                              />
                              {isError ? (
                                <div className="name-required">
                                  {errorMessage}
                                </div>
                              ) : null}
                            </Col>
                            <Col md={6}>
                              <Row>
                                <Col sm={6} className="mb-2">
                                  <label htmlFor="deliveryTimeSlot" className="form-label">
                                    Delivery Time
                                  </label>
                                  <Input
                                    name="deliveryTimeSlot"
                                    placeholder="Delivery Time"
                                    bsSize="sm"
                                    type="select"
                                    className="form-control-sm"
                                    onChange={addtoCartValidation.handleChange}
                                    onBlur={addtoCartValidation.handleBlur}
                                    value={
                                      addtoCartValidation.values
                                        .deliveryTimeSlot || ""
                                    }
                                    invalid={
                                      addtoCartValidation.touched
                                        .deliveryTimeSlot &&
                                        addtoCartValidation.errors
                                          .deliveryTimeSlot
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value={""}>Select</option>
                                    {TimeSolteDetails.map(item => {
                                      return (
                                        <option
                                          key={item.Key}
                                          value={item.Value}
                                        >
                                          {item.Value}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {addtoCartValidation.touched
                                    .deliveryTimeSlot &&
                                    addtoCartValidation.errors
                                      .deliveryTimeSlot ? (
                                    <FormFeedback type="invalid">
                                      {
                                        addtoCartValidation.errors
                                          .deliveryTimeSlot
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col sm={6} className="mb-2">
                                  <label htmlFor="returnTimeSlot" className="form-label">
                                    Return Time
                                  </label>
                                  <Input
                                    name="returnTimeSlot"
                                    placeholder="Return Time"
                                    bsSize="sm"
                                    type="select"
                                    className="form-control-sm"
                                    onChange={addtoCartValidation.handleChange}
                                    onBlur={addtoCartValidation.handleBlur}
                                    value={
                                      addtoCartValidation.values
                                        .returnTimeSlot || ""
                                    }
                                    invalid={
                                      addtoCartValidation.touched
                                        .returnTimeSlot &&
                                        addtoCartValidation.errors.returnTimeSlot
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value={""}>Select</option>
                                    {TimeSolteDetails.map(item => {
                                      return (
                                        <option
                                          key={item.Key}
                                          value={item.Value}
                                        >
                                          {item.Value}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {addtoCartValidation.touched.returnTimeSlot &&
                                    addtoCartValidation.errors.returnTimeSlot ? (
                                    <FormFeedback type="invalid">
                                      {
                                        addtoCartValidation.errors
                                          .returnTimeSlot
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                            <Col md={isIpad ? 3 : 2}
                              className="mb-2 check_button Button_aline available_button"
                            >
                              {(bookingDataStatus == 1 || bookingDataStatus == 3) &&
                                cartButton && !isError ? (
                                <>
                                  {formSumitLoading ? (
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      style={{
                                        width: "100%",
                                        cursor: "not-allowed",
                                      }}
                                    >
                                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                      Loading
                                    </button>
                                  ) : (
                                    <Button
                                      color="primary"
                                      type="submit"
                                      className="btn btn-primary btn-sm w-full mt-1"
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={e => {
                                        e.preventDefault()
                                        addtoCartValidation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      Add to Cart
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  className="btn btn-sm w-full mt-1"
                                  style={{
                                    width: "100%",
                                    cursor: "not-allowed",
                                    backgroundColor: "#343a4030",
                                    border: "1px solid #343a4014",
                                    color: "#000",
                                  }}
                                >
                                  Add to Cart
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    {bookingAvailableList.length > 0 &&
                      bookingDataStatus === 3 ? (
                      <div ref={scrollRef}>
                        <div>
                          <div>
                            <TableList
                              columns={columns}
                              data={bookingAvailableList}
                              isGlobalFilter={true}
                              isCategoryList={true}
                              customPageSize={10}
                              isLoading={isLoading}
                              className="custom-header-css"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          ref={scrollRef}
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            {bookingAvailableList.length === 0 &&
                              bookingDataStatus === 1 && (
                                <Tbody>
                                  <Tr>
                                    <Th
                                      className="text-center"
                                      style={{
                                        color: "#28A745",
                                        fontSize: "20px",
                                      }}
                                    >
                                      Available
                                    </Th>
                                  </Tr>
                                </Tbody>
                              )}
                            {bookingAvailableList.length === 0 &&
                              bookingDataStatus === 2 && (
                                <Tbody>
                                  <Tr>
                                    <Th
                                      className="text-center"
                                      style={{
                                        color: "#DC3545",
                                        fontSize: "20px",
                                      }}
                                    >
                                      Not available
                                    </Th>
                                  </Tr>
                                </Tbody>
                              )}
                          </Table>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>

                {roleName == "Vendor" &&
                  <Card className="mt-3">
                    <Row>
                      <Col sm={3} className="mb-2">
                        <label className="form-label">
                          Customer
                        </label>
                        <Input
                          name="deliveryTimeSlot"
                          placeholder="Delivery Time"
                          bsSize="sm"
                          type="select"
                          className="form-control-sm"
                          value={selectedCustomer}
                          onChange={e => { setSelectedCustomer(e.target.value), setCustomerNameRequired(false) }}
                          invalid={customerNameRequired}
                        >
                          <option value={""}>Select</option>
                          {cartCustomer.map(item => {
                            return (
                              <option
                                key={item.Key}
                                value={item.Value}
                              >
                                {item.Value}
                              </option>
                            )
                          })}
                        </Input>
                        {customerNameRequired ? (
                          <FormFeedback type="invalid">
                            {
                              'Customer is required'
                            }
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} style={{ marginTop: "19px" }}>
                        <>
                          {createBillLoading ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              style={{
                                width: "100%",
                                cursor: "not-allowed",
                              }}
                            >
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              type="button"
                              className="btn btn-primary btn-sm w-full mt-1"
                              style={{
                                width: "100%",
                              }}
                              onClick={() => handleCreateBooking()}
                            >
                              Quick Bill
                            </Button>
                          )}
                        </>
                      </Col>
                    </Row>
                  </Card>
                }
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <AddCartPage isChangeTab={isChangeTab} />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <div className="modal-content ">
                <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                  Product already in Cart
                </ModalHeader>
                <ModalBody>
                  <TableList
                    columns={columns1}
                    data={cartAvailableList}
                    className="custom-header-css"
                  />
                </ModalBody>
                <Row>
                  <Col
                    md="12"
                    className="text-end"
                    style={{ marginBottom: "5px" }}
                  >
                    <Button
                      color="secondary"
                      className="btn btn-primary btn-sm me-2"
                      type="button"
                      onClick={() => toggle()}
                    >
                      Cancle
                    </Button>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm me-2"
                      type="submit"
                      onClick={() => {
                        handaleConfirm()
                      }}
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal>

            <Modal isOpen={modalAvailability} toggle={toggleAvailability} centered={true}
              size="lg"
            // style={{ width: "350px" }}
            >
              <div className="modal-content ">
                <ModalHeader className="text-centre" toggle={toggleAvailability} tag="h5">
                </ModalHeader>
                <ModalBody>
                  <TableList
                    columns={columns}
                    data={bookingAvailableList}
                    isGlobalFilter={true}
                    isCategoryList={true}
                    customPageSize={10}
                    isLoading={isLoading}
                    className="custom-header-css"
                  />
                </ModalBody>
                <Row>
                  <Col
                    md="12"
                    className="text-end"
                    style={{ marginBottom: "5px" }}
                  >
                    <Button
                      color="secondary"
                      className="btn btn-primary btn-sm me-2"
                      type="button"
                      onClick={() => toggleAvailability()}
                    >
                      Cancle
                    </Button>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm me-2"
                      type="submit"
                      onClick={() => {
                        handaleConfirmAvailability()
                      }}
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal>

            <Modal isOpen={modalViewHistory} toggle={toggleViewHistory} centered={true}
              size="xl"
            >
              <div className="modal-content">
                <ModalHeader className="text-centre" toggle={toggleViewHistory} tag="h5">
                  Product History
                </ModalHeader>
                <ModalBody>
                  <TableList
                    columns={columns2}
                    data={productViewHistoryDetail}
                    className="custom-header-css"
                  />
                </ModalBody>
              </div>
            </Modal>
          </Card>
          <Card>
            {customActiveTab == "1" ? (
              <Row>
                <Col>
                  <Accordion open={open} toggle={toggleAccordion}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        Product Details
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <div className="d-flex gap-3">
                          {
                            <img
                              style={{
                                cursor: "pointer",
                                border: "1px solid #ced4da",
                                borderRadius: "3px",
                              }}
                              width="125px"
                              height="150px"
                              src={
                                productDetails?.ProductImage
                                  ? productDetails?.ProductImage
                                  : uploadImage
                              }
                            />
                          }
                          <div>
                            <div className="mt-2 " style={{ fontSize: "12px" }}>
                              <div>
                                <b> Name: </b>
                                {productDetails.ProductName ?? "N/A"}
                              </div>

                              <div className="mt-2">
                                <b> Code: </b>
                                {productDetails.ProductName != "N/A" ? addtoCartValidation.values.productCode :
                                  "N/A"}
                              </div>

                              <div className="mt-2">
                                <b> Rent: </b>
                                <i className="mdi mdi-currency-inr"></i>
                                {new Intl.NumberFormat('en-IN', {
                                  currency: 'INR',
                                  minimumFractionDigits: 2,
                                }).format(productDetails?.Rent || 0)}
                              </div>

                              <div className="mt-2">
                                <b>Color: </b>
                                {productDetails.ColorName ?? "N/A"}
                              </div>

                              <div className="mt-2">
                                <b>Size: </b> {productDetails.SizeName ?? "N/A"}
                              </div>

                              <div className="mt-2">
                                <b>Qty: </b> {productDetails.Qty ?? 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>
            ) : null}
          </Card>
        </Container>
      </div>

      <Modal
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setModal2(!modal2)} tag="h5">
            {"SubAdmin Login"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                subAdminValidation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                subAdminValidation.handleReset()
                return false
              }}
            >
              <FormGroup>
                <Label htmlFor="validationCustom03" className="required">
                  Username
                </Label>
                <InputGroup className="mb-2">
                  <Input
                    innerRef={inputRef}
                    className="form-control-sm"
                    name="Username"
                    value={subAdminValidation.values.Username || ""}
                    type="text"
                    placeholder="Username"
                    // onChange={subAdminValidation.handleChange}
                    onChange={(e) => {
                      if (isFocus) {
                        inputRef.current.focus();
                        inputRef.current.select();
                        setIsFocus(false)
                      }
                      subAdminValidation.handleChange(e);
                    }}
                    onBlur={subAdminValidation.handleBlur}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        subAdminValidation.handleSubmit()
                        return false
                      }
                    }}
                    invalid={
                      subAdminValidation.touched.Username &&
                        subAdminValidation.errors.Username
                        ? true
                        : false
                    }
                  />
                  {subAdminValidation.touched.Username &&
                    subAdminValidation.errors.Username ? (
                    <FormFeedback type="invalid">
                      {subAdminValidation.errors.Username}
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="validationCustom03" className="required">
                  Password
                </Label>
                <InputGroup className="mb-2">
                  <Input
                    className="form-control-sm"
                    name="Password"
                    value={subAdminValidation.values.Password || ""}
                    type={passwordShow ? "text" : "password"}
                    placeholder="Password"
                    onChange={subAdminValidation.handleChange}
                    onBlur={subAdminValidation.handleBlur}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        subAdminValidation.handleSubmit()
                        return false
                      }
                    }}
                    invalid={
                      subAdminValidation.touched.Password &&
                        subAdminValidation.errors.Password
                        ? true
                        : false
                    }
                  />
                  <Button
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-sm"
                    style={{ border: "1px solid #ced4da" }}
                    color="light"
                  >
                    {passwordShow ? (
                      <i className="mdi  mdi-eye-off-outline" />
                    ) : (
                      <i className="mdi mdi-eye-outline" />
                    )}
                  </Button>
                  {subAdminValidation.touched.Password &&
                    subAdminValidation.errors.Password ? (
                    <FormFeedback type="invalid">
                      {subAdminValidation.errors.Password}
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>

              <div className="text-end">
                <>
                  {subAdminPasswordLoading ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                      Loading
                    </button>
                  ) : (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm "
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        subAdminValidation.handleSubmit()
                        return false
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

    </React.Fragment>
  )
}

export default Avaiblity

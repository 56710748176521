import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Form,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import { GetObjectsByAccountGroups } from "_mock/Static_data"
import { dateFormat, getCurrentFinancialYear } from "pages/Common"
import CreateAccountCommonModel from "../CreateAccountCommonModel"

let isSelectedFinancialYear = false
function IncomeModel({ modal, toggle, IncomeDetails, getTableData }) {
  const { postApi, putApi, getApi } = useApi()
  const [isLoading, setisLoading] = useState(false)
  const [incomeAccounts, setIncomeAccounts] = useState([])
  const startDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[0]
  const endDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[1]
  const endDateYear = endDate.split('-')[2] + "-" + endDate.split('-')[1] + '-' + endDate.split('-')[0]
  const [modal1, setModal1] = useState(false)
  const [isAccountCreate, setIsAccountCreate] = useState(false)

  useEffect(() => {
    const SelectedFinancialYear = startDate?.split("-")[2] + "-" + (endDate?.split("-")[2])
    if (SelectedFinancialYear == getCurrentFinancialYear()) {
      isSelectedFinancialYear = true
      validation.setFieldValue("Date", new Date())
    } else {
      isSelectedFinancialYear = false
      validation.setFieldValue("Date", endDateYear)
    }
  }, [localStorage.getItem("FinancialYearDate")])

  const accountGroupApi = () => {
    getApi(`Accounts/accounts-lookup?accountGroup=${GetObjectsByAccountGroups(6)[0].Value}`).then(({ data, status }) => {
      if (status === 200) {
        setIncomeAccounts(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  useEffect(() => {
    accountGroupApi()
  }, [])

  useEffect(() => {
    validation.handleReset()
  }, [modal])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Date: IncomeDetails
        ? IncomeDetails.Date.slice(0, 10) :
        !isSelectedFinancialYear ? endDateYear : new Date().toISOString().slice(0, 10),
      Name: IncomeDetails ? IncomeDetails.Name : "",
      Amount: IncomeDetails ? IncomeDetails.Amount : "",
      PaymentMode: IncomeDetails ? IncomeDetails.PaymentMode : 1,
      Details: IncomeDetails ? IncomeDetails.Details : "",
      accountId: IncomeDetails ? IncomeDetails.CrAccountId : incomeAccounts[0]?.Id,
    },
    validationSchema: Yup.object({
      Date: Yup.string().required("The date is required"),
      Name: Yup.string().required("The name is required"),
      Amount: Yup.string().required("The amount is required"),
      Details: Yup.string().required("The details is required"),
      accountId: Yup.string().required("The income account is required"),
    }),
    onSubmit: values => {
      setisLoading(true)

      values.PaymentMode = parseInt(values.PaymentMode)
      values.Amount = parseFloat(values.Amount)
      values.accountId = parseInt(values.accountId)

      let url = IncomeDetails ? "income/update" : "income/create"

      let Api = IncomeDetails ? putApi : postApi
      if (IncomeDetails) {
        values.Id = IncomeDetails.Id
      }
      const tostMessage = IncomeDetails
        ? "Update Income Successfully"
        : "Add Income Successfully"

      Api(url, values).then(({ data, status }) => {
        setisLoading(false)
        if (status === 200) {
          setisLoading(false)
          toggle()
          getTableData()
          validation.handleReset()
          toast.success(tostMessage, {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          setisLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  // allow only number key
  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
    }
  };

  const handleCreateAccount = () => {
    toggle1()
  }
  const toggle1 = () => {
    setModal1(!modal1)
    setIsAccountCreate(true)
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle} tag="h4">
            {IncomeDetails ? `Update Income / ${IncomeDetails?.IncomeNo || ""}` : "Add Income"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation.handleReset()
                return false
              }}
            >
              <Row>
                <Col md={8}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="formrow-InputState" className="required">
                      Income Acc.
                    </Label>
                    <Input
                      type="select"
                      bsSize="sm"
                      id="formrow-InputState"
                      className="form-control"
                      name="accountId"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.accountId}
                      invalid={
                        validation.touched.accountId &&
                          validation.errors.accountId
                          ? true
                          : false
                      }
                    >
                      {incomeAccounts.map((item, index) => {
                        return (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        )
                      })}
                    </Input>
                    {validation.touched.accountId &&
                      validation.errors.accountId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.accountId}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={4} style={{ marginTop: "22px" }}>
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm "
                    type="button"
                    onClick={() => handleCreateAccount()}
                  >
                    Create Acc.
                  </Button>
                </Col>
              </Row>
              <FormGroup className="mb-2">
                <Label htmlFor="formrow-InputState" className="required">
                  Name
                </Label>
                <Input
                  type="text"
                  bsSize="sm"
                  id="formrow-InputState"
                  className="form-control"
                  name="Name"
                  placeholder="Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Name || ""}
                  invalid={
                    validation.touched.Name && validation.errors.Name
                      ? true
                      : false
                  }
                />

                {validation.touched.Name && validation.errors.Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Name}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup className="mb-2">
                <div className="row">
                  <div className="col-md-6">
                    <Label htmlFor="validation1Custom01">Date</Label>
                    <Input
                      name="Date"
                      placeholder="date placeholder"
                      type="date"
                      min={dateFormat(startDate, "yyyy-dd-MM")}
                      max={endDateYear}
                      dateFormat="DD/M/YYYY"
                      className="form-control-sm"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.Date}
                    />
                  </div>
                  <div className="col-md-6">
                    <Label htmlFor="formrow-InputState" className="required">
                      Amount
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      id="formrow-InputState"
                      classAmount="form-control"
                      name="Amount"
                      placeholder="0.00"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      onKeyPress={handleKeyPress}
                      value={validation.values.Amount || ""}
                      invalid={
                        validation.touched.Amount && validation.errors.Amount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.Amount && validation.errors.Amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="mb-2">
                <Label htmlFor="formrow-InputState" className="required">
                  Payment Mode
                </Label>
                <Input
                  type="select"
                  bsSize="sm"
                  id="formrow-InputState"
                  className="form-control"
                  name="PaymentMode"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.PaymentMode || ""}
                  invalid={
                    validation.touched.PaymentMode &&
                      validation.errors.PaymentMode
                      ? true
                      : false
                  }
                >
                  <option value={1}>Cash</option>
                  <option value={2}>Bank</option>
                </Input>
                {validation.touched.PaymentMode &&
                  validation.errors.PaymentMode ? (
                  <FormFeedback type="invalid">
                    {validation.errors.PaymentMode}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup className="mb-2">
                <Label htmlFor="validationCustom03" className="required">
                  Details
                </Label>
                <Input
                  name="Details"
                  placeholder="type your Details here"
                  type="textarea"
                  row={2}
                  className="form-control-sm"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Details || ""}
                  invalid={
                    validation.touched.Details && validation.errors.Details
                      ? true
                      : false
                  }
                />
                {validation.touched.Details && validation.errors.Details ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Details}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <div className="text-end">
                {isLoading ? (
                  <button type="button" className="btn btn-primary btn-sm">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                    Loading
                  </button>
                ) : (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm "
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      {isAccountCreate &&
        <CreateAccountCommonModel
          modal={modal1}
          toggle={toggle1}
          Account={"Income"}
          accountGroupApi={accountGroupApi}
        />
      }
    </div>
  )
}

export default IncomeModel
